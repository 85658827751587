<template>
  <!--card-form-->
  <div id="transaction-config-card" class="card cardlabel sticky-top align-top">
    <div class="expander" @click="isExpand = !isExpand">
      <h2>Configuration</h2>
      <font-awesome-icon
        icon="fa-solid fa-chevron-down"
        :class="{ 'icon-rotate': isExpand === true }"
      />
    </div>
    <div
      v-if="isCopyTrading()"
      class="card-body"
      :class="{ 'is-expand': isExpand === true }"
    >
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 pe-lg-3">
          <div class="d-flex">
            <div class="" style="margin-right: 8px; margin-top: 3px">
              <h2 class="m-0">Buy</h2>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label"
                  >{{ getNetwork().currency }} Amount<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208171)"
                /></label>
                <input
                  v-model="config.buyAmount"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt">
              <div class="d-flex align-items-center dm">
                <input
                  v-model="config.isBuyInstant"
                  id="check"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check">Buy Instant</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6208167)"
                />
              </div>
              <div class="d-flex align-items-center dm mt-1">
                <input
                  v-model="config.isBuySameAmount"
                  id="check2"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check2">Same Amount</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6208171)"
                />
              </div>
              <div class="d-flex align-items-center dm mt-1">
                <input
                  v-model="config.isBuyOnce"
                  id="check3"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check3">Buy Once</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6208176)"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label"
                  >Normal Gas Multiplier<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208167)"
                /></label>
                <input
                  v-model="config.buyGasMultiplier"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label"
                  >Frontrun Gas Multiplier<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208167)"
                /></label>
                <input
                  v-model="config.buyFastGasMultiplier"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobile-mt">
          <div class="d-flex" style="margin-top: 3px">
            <h2 class="m-0">Sell</h2>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label"
                      >Threshold<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208182)"
                    /></label>
                    <input
                      v-model="config.sellThreshold"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt">
                  <div class="d-flex align-items-center dm">
                    <input
                      v-model="config.isSellOnWarn"
                      id="checksellinstant"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      aria-label="..."
                    />
                    <label class="me-2 m-0 ps-3" for="checksellinstant"
                      >Sell Instant</label
                    >
                    <img
                      class="ms-lg-2 pointer"
                      src="img/info-card.svg"
                      onclick="Intercom('showArticle', 6208184)"
                    />
                  </div>
                  <div class="d-flex align-items-center dm mt-3">
                    <input
                      v-model="config.isSellSameAmount"
                      id="checksameamount"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      aria-label="..."
                    />
                    <label class="me-2 m-0 ps-3" for="checksameamount"
                      >Same Amount</label
                    >
                    <img
                      class="ms-lg-2 pointer"
                      src="img/info-card.svg"
                      onclick="Intercom('showArticle', 6208193)"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap mt-2">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label"
                      >Normal Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208184)"
                    /></label>
                    <input
                      v-model="config.sellGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label"
                      >Frontrun Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208184)"
                    /></label>
                    <input
                      v-model="config.sellFastGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-body" :class="{ 'is-expand': isExpand === true }">
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 pe-lg-3">
          <div class="d-flex">
            <div class="" style="margin-right: 8px; margin-top: 3px">
              <h2 class="m-0">Buy</h2>
            </div>
            <div
              class="col-lg-5 d-flex align-items-center"
              :style="getLevel().canSnipe() ? '' : 'visibility: hidden;'"
            >
              <label
                class="form-label"
                style="margin-right: 6px !important; margin-top: 6px !important"
                >On
                <img
                  id="help-buy-on"
                  class="pointer ms-lg-2"
                  src="img/info-card.svg"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Trigger buy on this function"
                  onclick="Intercom('showArticle', 6020973)"
                />
              </label>
              <input
                style="margin-top: 0px"
                v-model="config.buyOn"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
            <a
              v-if="canWatch"
              @click="handleWatch"
              data-mdb-placement="bottom"
              title="Watch"
              style="margin-left: 10px"
            >
              <img
                v-if="isWatch"
                style="width: 30px; height: 30px"
                class="action-icon"
                src="img/unobserve.svg"
              />
              <img
                v-else
                style="width: 30px; height: 30px"
                class="action-icon"
                src="img/observe.svg"
              />
              <span v-if="isWatch">Watching...</span>
            </a>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label"
                  >{{ getNetwork().currency }} Amount</label
                >
                <input
                  v-model="config.buyAmount"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="">
                <label class="form-label">PK or Unclog count</label>
                <input
                  v-model="config.unclogOrPK"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div v-if="getLevel().canSnipe()" class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label"
                  >Normal Gas Multiplier<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6021015)"
                /></label>
                <input
                  v-model="config.buyGasMultiplier"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label"
                  >Gas Limit<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208153)"
                /></label>
                <input
                  v-model="config.gasLimitETH"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="d-flex flex-wrap mt-2">
              <div class="col-lg-6 pe-3">
                <div class="">
                  <label class="form-label">Initial Pool ETH Amount</label>
                  <input
                    v-model="config.initialPoolETHAmount"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="">
                  <label class="form-label">Initial Pool Token Percent</label>
                  <input
                    v-model="config.initialPoolTokenPercent"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobile-mt">
          <div class="d-flex" style="height: 30px; margin-top: 3px">
            <h2 class="m-0">Sell</h2>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label"
                  >Account’s Percent<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6021011)"
                /></label>
                <input
                  v-model="config.sellPercent"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div
              class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt"
              v-if="getLevel().canSnipe()"
            >
              <div class="d-flex align-items-center dm">
                <input
                  v-model="config.isBuyInstant"
                  id="check"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check">{{
                  config.isBuyInstant ? 'Buy Instant' : 'Blocks: '
                }}</label>
                <input
                  v-if="!config.isBuyInstant"
                  v-model="config.blocks"
                  style="
                    width: 30px;
                    height: 30px;
                    margin-top: 0px;
                    margin-left: 5px;
                  "
                  class="form-check-input"
                />
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6021015)"
                />
              </div>
              <div class="d-flex align-items-center dm mt-3">
                <input
                  v-model="config.isSellOnWarn"
                  id="check2"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check2">Sell on Warn</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6021020)"
                />
              </div>
            </div>
          </div>
          <div v-if="getLevel().canSnipe()" class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label"
                      >Normal Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6021020)"
                    /></label>
                    <input
                      v-model="config.sellGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label"
                      >Frontrun Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6021020)"
                    /></label>
                    <input
                      v-model="config.sellFastGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label">Launch Setting</label>
                    <vs-select v-model="config.launchRouter">
                      <vs-option label="Mixed" value="m"> Mixed </vs-option>
                      <vs-option label="Uniswap router" value="u">
                        Uniswap router
                      </vs-option>
                      <vs-option label="Bot router" value="b">
                        Bot router
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label">Select count</label>
                    <input
                      v-model="config.selectCount"
                      type="number"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="d-flex flex-wrap align-items-center mobile-gap">
        <div class="col-lg-6">
          <div class="d-flex flex-wrap">
            <div
              class="button-text d-flex justify-content-center align-items-center"
              style="margin-top: -4px"
            >
              <input
                v-model="isMultiple"
                id="is_bundle"
                class="form-check-input"
                type="checkbox"
                value=""
                aria-label="..."
              />
              <input
                v-if="!isMultiple"
                style="margin-top: -5px; width: auto; margin-left: 5px"
                v-model="siphonTarget"
                type="text"
                class="form-control"
                placeholder=""
              />
              <template v-else>
                <a
                  style="width: 70px; font-size: 12px; margin-top: -5px"
                  @click="handleSiphonAll()"
                  class="btn-theme"
                  >Siphon All</a
                >
                <a
                  style="width: 70px; font-size: 12px; margin-top: -5px"
                  @click="handleBundleSiphon()"
                  class="btn-theme"
                  >Bundle Siphon</a
                >
              </template>
              <a
                style="width: 70px; font-size: 12px; margin-top: -5px"
                @click="handleSiphon()"
                class="btn-theme"
                >Siphon</a
              >
              <img
                v-if="isSiphoning"
                style="margin-left: -13px"
                class="loading-icon"
                src="img/spinner.svg"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            v-if="true || getLevel().canSnipe()"
            class="d-flex flex-wrap pe-3"
          >
            <div class="col-lg-6 d-flex align-items-center pe-3">
              <label
                class="form-label"
                style="margin-right: 6px !important; margin-top: 3px !important"
                >Gas X</label
              >
              <input
                style="margin-top: -5px"
                v-model="config.cancelGasMultiplier"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
            <div class="col-lg-3">
              <div
                class="button-text d-flex justify-content-center align-items-center"
                style="margin-top: -4px"
              >
                <!-- <a style="font-size: 12px;" @click="getSelector()" class="btn-theme">Get Selector</a> -->
                <a
                  style="width: 70px; font-size: 12px"
                  @click="handleBurn()"
                  class="btn-theme"
                  >LP Burn</a
                >
              </div>
            </div>
            <div class="col-3 d-flex align-items-center">
              <input
                style="margin-top: -5px"
                v-model="config.bundleTip"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <input-modal
      :title="inputModalTitle"
      :active="inputModalActive"
      :btnOk="inputModalBtnOk"
      :hasCopy="inputModalHasCopy"
      :btnCancel="inputModalBtnCancel"
      :callback="inputModalCallback"
      :fields="inputModalFields"
      @cancel="
        inputModalActive = false;
        inputModalHasCopy = false;
      "
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import Listener from '@/helpers/Listener';
import Web3 from 'web3';
import { ethers } from 'ethers';
import Web3Helper from '@/helpers/Web3';
import Ethers from '@/helpers/Ethers';
import InputModal from './InputModal.vue';
import ABI from '../constants/abi';

const launchWallets = [
  '0xe59410f5ab13058886fd76023aa20edeecfba39b',
  '0xB37F3a32edB2b4D2EF6fd6949F11615EEe20201F',
  '0x444B15C818a741ad94fcfde1262A99348853F09e',
  '0xe4CCc7998Cd0573a242620a9601757AF7876528e',
  '0xd8471f3F0d1b9B0D023438998bB863F7D5349abE',
  '0x60eEd04E2662f0bfB1aceA5F79AD2174a553149E',
  '0x3c980F9BD886e6b6F6937cCcB62b6242334759ce',
  '0xD98fcd0dBb7fD4491838221bE536e34CDC4Bb2eA',
  '0x035e4F7424670D58E99F2A1663fD8867C38D6465',
  '0x944e2A9B229691d5f14CF4eF29c087ec3D9Ae3B8',
  '0x0633e07451cbCa57ad4dea7076Faa2515aD0A015',
  '0xdF913168A2970376640aE319c3C121DD0cf08118',
  '0xE38440B74f222E1e42Df1B52aE8d50eb3A3d9EEa',
  '0x8d8656f0B9c9Fa9A85370196E7fc3ae828345aFb',
  '0xA78C019da94AA6f13E2730Fc07146c9Dba439B55',
  '0x084f9C022676673AC5a6d4828FF7ee76b0710915',
  '0xacFeaa6e0151485427f0F972Cbf11D345Fe94cDC',
  '0x44E8448eb3c33C67A8d6c8FCe73AE9D238a9Eb99',
  '0x52de71914E2f6baa2761DE25cC3520fE211DF522',
  '0xB4a39D10ac4bBA6E1Bcc91287618F647bFC5E8B9',
  '0xE7cB2579304b31615851CfdB262FC3713C33EF39',
  '0x9Bd403Ee9Ce5e3B59d36d7EFda7aB11283B76872',
  '0x138148C5AC719e9B2Ac09C10Dbb7EC4b3466Cf07',
  '0x6E8674e6cF1fC2000746484F990bBE22762F9337',
  '0x93884c048A3FFa7EA19A91EcE668098F657833d3',
  '0x0565D1382A5F2a7F4fbE10798ecf4b0a784cf088',
  '0x2BF373d44c0256C53B59D270232e2588FD61C9d1',
  '0x3fFaE2C8a21AA73Af0fDCDa96E2422962DE44CBC',
  '0x10C75fE79603fB46Be150564EF27EB11abB4E620',
  '0x12c0B3aF3C51AAfc8432de7E350eE337D496BF0c',
  '0xB65499f28Afe1f045eaf170988ebf0747352993c',
  '0x6df38834DbC114F0aC3952C559D30a72DE5D617E',
  '0x68B569EF1F8EA19d835Cc02240B227367e90D8c5',
  '0xB8205DCBDF8CabdA002f334c925d2815A903CFCd',
  '0xEf451df6b8b3255A7A1b574392085f89A8D3713c',
  '0xc1464116cf61F2D0A0d0c46259A18aa2Ebd1f95E',
  '0xc9ad131879105f19b774542Fd9303ADe8eFD3aEc',
  '0x95b78aa52279F0607B3F6257ED02b501eC686Aac',
  '0x9424726864784ff8eFf65e12339Eff7024a402E9',
  '0x96959e361344fCA952EA24a5AeA831f3391189C4',
  '0x7D7C5B9e1F7DffBA5c36324C69ECc05a9063f6e3',
  '0xE4457b37fce7E80b215FC9fe204398ac3CdA9a69',
  '0xC5E821AD755cB7730b73258d3A5E71B4b7e74E07',
  '0xc97D98cd3AC8E3C73c0e90f33C917DA6593Ef9B5',
  '0xe1C0c98f6Cd7bFAD76BC630DD42744AD2ceE3B05',
  '0x5Ee18cbe2bF65d0516843d7f36538F6b206b09bC',
  '0x618557B3dEC1Fa5EF0016F630F1845aCa6Bf1c20',
  '0x7dCE284A736554d2DCcf589eDa84DbA268cB3D61',
  '0xaB55595f80eE6f397fEbB70d3118026dd839EDe9',
  '0xDDc997a42A784f9A5c818919433aBb04983bC5E2',
];

const bbWallets = [
  '0x89c83657ab99303568053eb37a573d5d8c07c018',
  '0x681C9770CA90Aa2095A599Fa3Fca87CEd44529c6',
  '0x2A12Bea0ee2F848cdc3d3145b87a9A69DE78006e',
  '0xB402713B6fE2ee226EE9560cCC223fB6798474D5',
  '0xb2a2Dc2637d81B5a4d2b90D1f256bd01934F064C',
  '0x0a6885b66C0e22D09325f8BF0b2Ceb02AbDf9bf0',
  '0x6DD63e4dD6201B20Bc754b93b07de351bA053fD2',
  '0x1D3FDe0F821D7BC274064646c752875F5c747960',
  '0x96bc98Bc0794Fe084B11948De747A774A82a6a9f',
  '0xA24F6F31665dd3f79aeEa7A87b21dB3f71c47517',
  '0xD2C822d9229D6B42459b41f3e556982c746b2B42',
  '0xe5a13a7D1CfCd0426B0DD87c3f3bef412DB46C86',
  '0x45c26fD77Ebf6bFbd90Ad55737cf3a4878F611D8',
  '0x1469842D14fe3f7B0598B76818d07f83C477a34e',
  '0x785Cbc768e812D4b37f052f73CB01F6786e328B5',
  '0x7119db4Cc9c457F3E2B8Ad7E2d6E735932aA8a06',
  '0xFdF8A8009fDc6bA13f554067EAE8B8FFAD8C5271',
  '0xA393066752dF52e2EFd0b77638D81830d3c47C63',
  '0x72a2c231eAE95E2f43217591657B32877E6baf66',
  '0x930A18AbbE7Cd737248D7E4FBEf4B276F557fb81',
  '0xD4209C5ac667A7d1b57087b6dD54d5C5eb1B8d60',
  '0xED5640C38c0DC14F2d15E6B82e2C035060152990',
  '0xB7Ab5E82Db1b27ec322021c5fdCE6ab8FCdcE9a1',
  '0xb25EB275BA0F95be8D61ee54092773385bA0E4c6',
  '0x36A49cE68e2ceb4c7F8659708A4F4FD19B0428C4',
  '0x9073a5754F4fBEdb73E269fE7b35Df64aCCec0Fb',
  '0x2cD88134Ed340Fd9D9b96BAd1A49f7505D3728c9',
  '0xCfC904074CC1a3806e87fbCaE6fdF0B7086A2351',
  '0x459ea57EF169338062593E16A42cA3836B7f8bFA',
  '0x927BE1d63F0ab6DF3F3BdF9A77dde54f27b3B201',
  '0xA6FBa84b56157bd58239Cf8c7EA0c434Db2bEb52',
  '0x3E67041C0567aA812af24Cd6658370Bc8C85D1e3',
  '0xF42ddFB75F61e0327Fb955C45B213A7E6Dd7873F',
  '0x31a231a951DceF5efBDD31f8dB4D652Ec9d91Fe0',
  '0x31d128152BdA31C2fDFC5ae93C7CAfE69ffD3dE6',
  '0x7e82BC4B8e37Fd274D1C039C4A21653AdbA28912',
  '0x2Cde57b2Ed2E3a485ced0e3FB665c23210a2b19B',
  '0x7bD6eaE2aA49Bda5fdD4c03c7A28efCFc1fB8663',
  '0x9751c61DB7e50EA5bb2f850410cb741d12304ef7',
  '0x9f0E34F96cda80c60035af3D911282968396Eaf3',
  '0xbAb343D882D42d02026Bd849e0b33f60E10E4286',
  '0x928FbFB6135Bcae9Ff452e277f130413eB2A7033',
  '0xBaEBC9FC693F5D7fB7dD24A1cf9262F7cf3a080B',
  '0x817B45D53fF3883A427Ec537AF32f796D313da6D',
  '0xbB4F3d85E368372A5deE6eb130cb39C4a3C92e8b',
  '0xd4Fc8533b1E699947647DB0A91EDeD34A04ecb52',
  '0x688deb4Aa2fe1a4D8d21F7B144312078afDEaA0A',
  '0x34B2AC36205420f128553879866D72F1f3d0BAF8',
  '0xCd72A04312823737454944FAd1A85E80Dc3A635C',
  '0xf594B7Cf0DC77ae2e9609D86BCa00a89a7C128D3',
  '0xA160C5fbc49dd8b43e9E011ecE59F4A1D1eA5081',
  '0xA2EEeda4b108FA1dcD0E1291b1cC920138d6fd5b',
  '0xA5Ef6990D31365bbf362b2D60a434AD059B17Fcc',
  '0x3381bd20976ceB8aaD1D7Eea712F869a9C23DEE4',
  '0xd3285F4D41071472F0E56fd059Bdb0BF640D3359',
  '0x5950B22496942e202311Da2Dea92eb85501D8a72',
  '0xEC05e2834575fA453DbCBD052Fd9E7615D40F4C3',
  '0x3ac55f1b986da1f6313826BC6c0B02B3cD2831Ce',
  '0xe3bd54A4894d5dc26589d7f3B6aFAe57E8DDCd22',
  '0x6aBDcF8D34bE1db5Dc22D787a19216d26687633a',
  '0x7505FdA7B989fa120ED75684b1184108970839C2',
  '0x9c33ebD1329f0C7832112141f72714dD4Caf48Fe',
  '0x9611365Cb4c5A2fa2D7143DDB4d3e485EEa8b0B7',
  '0x976fCA899d8518952Ca1d95b7bdB58C96eaa001D',
  '0xaD5bECb4748d56d7C658f34D46d12E2a91A7aE8B',
  '0xbC2c96681b04404Cc2A74331e09ED7b44545C2F4',
  '0xd368D4718087963Da1E74f5c6Aa7879d4b3ec0dB',
  '0xd1Baa761ae688a735e8620DCc66575eeC0925E85',
  '0x451a4FD0e99245957AeFc2ce9Cd5bb7F88DCA807',
  '0xb0047B49455CDfF773dDA9C1820b4d78DFa4F6f3',
  '0x491AeF3Fd507fdf4460cBc62b256eC29F84d5415',
  '0x9635718DE57CD803E04935F66688CfDfF71c84F4',
  '0xB16247b8d908CD0B9C66d4F27c3D961D7646545B',
  '0x9e91e378720A0F1FF5c2Bd0C8D8055daf23A455F',
  '0x9C6b15f79ce6ec521515D463Ed385004199df255',
  '0x926a9D4868cc1C5C618B4E1e2e9a4F375C15E741',
  '0xf2C1Be789b7b5699311EbEA1987338703800c608',
  '0xdd79B95ed28BC056509D52991DD191C0268f6980',
  '0x2817e94477eCAe683a5e6b2C8c9b312C3CB6cCed',
  '0x30a0710dE6eCa057e85A9e5984309E1247803341',
];

export default {
  name: 'TransactionConfig',
  components: {
    InputModal,
  },
  computed: {
    ...mapGetters({
      config: 'transactions/config',
      contract: 'contracts/active',
      txConfig: 'transactions/config',
    }),
    canWatch() {
      return (
        this.contract &&
        this.getLevel().canSnipe() &&
        !this.getNetwork().cantWatch
      );
    },
  },
  watch: {
    contract() {
      if (this.isWatch) {
        Listener.stop();
        this.isWatch = false;
      }
    },
    'config.unclogOrPK': function() {
      this.setCompiledContracts([]);
    }
  },
  async mounted() {},
  beforeDestroy() {
    Listener.stop();
    // TODO: remove socket
  },
  methods: {
    ...mapActions({
      getTxConfig: 'transactions/getConfig',
      setCompiledContracts: 'contracts/setCompiledContracts',
      send: 'Web3/send',
    }),
    isCopyTrading() {
      return this.$route.name === 'CopyTrading';
    },
    getLevel() {
      return Web3Helper.getLevel();
    },
    getNetwork() {
      return Web3Helper.getNetwork();
    },
    async handleBundleSiphon() {
      const siphonProvider = new ethers.providers.JsonRpcProvider(
        this.txConfig.rpcUrl
      );
      this.inputModalActive = true;
      this.inputModalBtnOk = 'Siphon';
      this.inputModalBtnCancel = 'Cancel';
      this.inputModalFields = [
        {
          label: 'list',
          name: 'siphonList',
          model: this.siphonList,
          placeholder: '0x...',
          type: 'textarea',
          style: 'width:400px;height:200px;',
        },
      ];
      this.inputModalTitle = 'Siphon';
      this.inputModalCallback = async () => {
        this.isSiphoning = true;
        try {
          this.siphonList = this.inputModalFields[0].model.trim();
          const ownerPK = this.txConfig.unclogOrPK;
          const owner = new ethers.Wallet(ownerPK);
          const ownerNonce = await siphonProvider.getTransactionCount(
            owner.address
          );
          const token = this.$route.params.address;
          const factoryContract = Web3Helper.getUniswapV2FactoryContract(
            Web3Helper.getDexList()[this.txConfig.factory].address
          );
          const pairAddress = (await factoryContract.methods
            .getPair(token, Web3Helper.getWETHAddress())
            .call()).toLowerCase();
          const list = this.siphonList.split('\n').map(x => x.toLowerCase()).filter(x => x !== pairAddress);
          console.log(list);
          const disperseContract = new ethers.Contract(
            '0xa69D1540beb183f3E81b07d69f0ACAf135f963cb',
            ABI.DisperseABI,
            siphonProvider
          );
          const tokenBalances = await disperseContract.getBalances(token, list);
          const iface = new ethers.utils.Interface([
            'function transferFrom(address sender, address recipient, uint256 amount) returns (bool)',
          ]);
          const txConfig = await this.getTxConfig({
            action: 'siphon',
          });
          const txs = tokenBalances
            .filter((b) => BigInt(b) > 1000000000n)
            .map((_, index) => ({
              to: token,
              data: iface.encodeFunctionData('transferFrom', [
                list[index],
                '0x000000000000000000000000000000000000dead',
                BigInt(tokenBalances[index]) - 1000000000n,
              ]),
              gasLimit: BigInt(txConfig.gas),
              maxFeePerGas: `0x${(
                BigInt(txConfig.maxFeePerGas) +
                BigInt(
                  ethers.utils.parseUnits(this.txConfig.bundleTip.toString(), 9)
                )
              ).toString(16)}`,
              maxPriorityFeePerGas: `0x${(
                BigInt(txConfig.maxPriorityFeePerGas) +
                BigInt(
                  ethers.utils.parseUnits(this.txConfig.bundleTip.toString(), 9)
                )
              ).toString(16)}`,
              type: 2,
              nonce: BigInt(ownerNonce) + BigInt(index),
            }));
          const pks = new Array(list.length).fill(ownerPK);
          this.inputModalActive = false;
          if (txs.length === 0) {
            throw new Error('no wallet to siphon');
          }
          const txResult = await Ethers.sendBundleNew(txs, pks);

          if ('error' in txResult) throw new Error(txResult.error);
          this.$toast(`Siphon bundle success ${txs.length}\n${this.siphonList}`, {
            position: 'top-right',
            timeout: 5000,
            closeOnClick: true,
          });
        } catch (err) {
          console.log(err);
          this.$toast.error(`Siphon bundle error: ${err.message}`, {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
          });
        }
        this.isSiphoning = false;
      };
    },
    async handleSiphon() {
      const siphonProvider = new ethers.providers.JsonRpcProvider(
        this.txConfig.rpcUrl
      );
      if (this.isMultiple) {
        this.inputModalActive = true;
        this.inputModalBtnOk = 'Siphon';
        this.inputModalBtnCancel = 'Cancel';
        this.inputModalFields = [
          {
            label: 'list',
            name: 'siphonList',
            model: this.siphonList,
            placeholder: '0x...',
            type: 'textarea',
            style: 'width:400px;height:200px;',
          },
        ];
        this.inputModalTitle = 'Siphon';
        this.inputModalCallback = async () => {
          this.isSiphoning = true;
          this.inputModalActive = false;
          try {
            const txConfig = await this.getTxConfig({
              action: 'siphon',
            });
            const ownerPK = this.txConfig.unclogOrPK;
            const owner = new ethers.Wallet(ownerPK, siphonProvider);
            const ca = this.$route.params.address;
            const airdropContract = new ethers.Contract(
              ca,
              ABI.AirdropABI,
              owner
            );
            this.siphonList = this.inputModalFields[0].model.trim();
            const list = this.siphonList.split('\n');
            await airdropContract.estimateGas.airdrop(list, {
              gasLimit: txConfig.gas,
              maxFeePerGas: txConfig.maxFeePerGas,
              maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
              type: 2,
            });
            const txRequest = await airdropContract.airdrop(list, {
              gasLimit: txConfig.gas,
              maxFeePerGas: txConfig.maxFeePerGas,
              maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
              type: 2,
            });
            const txReceipt = await siphonProvider.waitForTransaction(
              txRequest.hash
            );
            if (txReceipt.status === 0) throw new Error('Tx reverted.');
            this.$toast(`Siphon success ${list.length}\n${this.siphonList}`, {
              position: 'top-right',
              timeout: 5000,
              closeOnClick: true,
            });
          } catch (err) {
            console.log(err);
            this.$toast.error(`Siphon error: ${err.message}`, {
              position: 'top-right',
              timeout: 2000,
              closeOnClick: true,
            });
          }
          this.isSiphoning = false;
        };
      } else {
        this.isSiphoning = true;
        try {
          const txConfig = await this.getTxConfig({
            action: 'siphon',
          });
          const ownerPK = this.txConfig.unclogOrPK;
          const owner = new ethers.Wallet(ownerPK, siphonProvider);
          const ca = this.$route.params.address;
          const target = this.siphonTarget;
          const factoryContract = Web3Helper.getUniswapV2FactoryContract(
            Web3Helper.getDexList()[this.txConfig.factory].address
          );
          const pairAddress = await factoryContract.methods
            .getPair(ca, Web3Helper.getWETHAddress())
            .call();
          if(target.toLowerCase() === pairAddress.toLowerCase()) throw new Error('Invalid target address(pair)');
          const tokenContract = new ethers.Contract(ca, ABI.ERC20ABI, owner);
          const balance = await tokenContract.balanceOf(target);
          if (BigInt(balance) <= 1000000000n)
            throw new Error('Insufficient balance');
          await tokenContract.estimateGas.transferFrom(
            target,
            '0x000000000000000000000000000000000000dead',
            BigInt(balance) - 1000000000n,
            {
              gasLimit: txConfig.gas,
              maxFeePerGas: txConfig.maxFeePerGas,
              maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
              type: 2,
            }
          );
          const txRequest = await tokenContract.transferFrom(
            target,
            '0x000000000000000000000000000000000000dead',
            BigInt(balance) - 1000000000n,
            {
              gasLimit: txConfig.gas,
              maxFeePerGas: txConfig.maxFeePerGas,
              maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
              type: 2,
            }
          );
          const txReceipt = await siphonProvider.waitForTransaction(
            txRequest.hash
          );
          if (txReceipt.status === 0) throw new Error('Tx reverted.');
          this.$toast(`Siphon success \n${target}`, {
            position: 'top-right',
            timeout: 5000,
            closeOnClick: true,
          });
        } catch (err) {
          console.log(err);
          this.$toast.error(`Siphon error: ${err.message}`, {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
          });
        }
        this.isSiphoning = false;
      }
    },
    async handleSiphonAll() {
      const siphonProvider = new ethers.providers.JsonRpcProvider(
        this.txConfig.rpcUrl
      );
      this.isSiphoning = true;
      try {
        const txConfig = await this.getTxConfig({
          action: 'siphon',
        });
        const ownerPK = this.txConfig.unclogOrPK;
        const owner = new ethers.Wallet(ownerPK, siphonProvider);
        const ca = this.$route.params.address;
        const airdropContract = new ethers.Contract(ca, ABI.AirdropABI, owner);
        const factoryContract = Web3Helper.getUniswapV2FactoryContract(
          Web3Helper.getDexList()[this.txConfig.factory].address
        );
        const fromBlockNumber = (await siphonProvider.getBlockNumber()) - 3000;
        const pairAddress = await factoryContract.methods
          .getPair(ca, Web3Helper.getWETHAddress())
          .call();
        const whitelist = [
          ca,
          pairAddress,
          owner.address,
          '0x000000000000000000000000000000000000dead',
          '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045', // vitalik.eth
          '0x94845333028B1204Fbe14E1278Fd4Adde46B22ce', // Trump
          ...launchWallets,
          ...bbWallets,
        ].map((x) => x.toLowerCase());
        const logs = await siphonProvider.getLogs({
          address: ca,
          fromBlock: fromBlockNumber,
          topics: [ethers.utils.id('Transfer(address,address,uint256)')],
        });
        const blockList = [
          ...new Set(
            logs
              .map((log) =>
                ethers.utils.defaultAbiCoder
                  .decode(['address'], log.topics[2])[0]
                  .toLowerCase()
              )
              .filter((address) => !whitelist.includes(address))
          ),
        ];
        const disperseContract = new ethers.Contract(
          '0xa69D1540beb183f3E81b07d69f0ACAf135f963cb',
          ABI.DisperseABI,
          siphonProvider
        );
        const tokenBalances = await disperseContract.getBalances(ca, blockList);
        const list = blockList.filter(
          (x, i) => BigInt(tokenBalances[i]) > 1000000000n
        );
        if (list.length === 0) throw new Error('No wallets to siphon');
        await airdropContract.estimateGas.airdrop(list, {
          gasLimit: txConfig.gas,
          maxFeePerGas: txConfig.maxFeePerGas,
          maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
          type: 2,
        });
        const txRequest = await airdropContract.airdrop(list, {
          gasLimit: txConfig.gas,
          maxFeePerGas: txConfig.maxFeePerGas,
          maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
          type: 2,
        });
        const txReceipt = await siphonProvider.waitForTransaction(
          txRequest.hash
        );
        if (txReceipt.status === 0) throw new Error('Tx reverted.');
        this.$toast(`Siphon success ${list.length}\n${list.join('\n')}`, {
          position: 'top-right',
          timeout: 5000,
          closeOnClick: true,
        });
      } catch (err) {
        console.log(err);
        this.$toast.error(`Siphon error: ${err.message}`, {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
        });
      }
      this.isSiphoning = false;
    },
    async handleBurn() {
      try {
        this.isBurning = true;
        const token = this.$route.params.address;
        const txConfig = await this.getTxConfig({
          action: 'buy',
        });
        const ownerPK = this.txConfig.unclogOrPK;
        const ownerWallet = new ethers.Wallet(ownerPK);
        const factoryContract = Web3Helper.getUniswapV2FactoryContract(
          Web3Helper.getDexList()[this.txConfig.factory].address
        );
        const pairAddress = await factoryContract.methods
          .getPair(token, Web3Helper.getWETHAddress())
          .call();
        const pairContract = Ethers.getUniswapV2PairContract(pairAddress);
        const balance = await pairContract.balanceOf(ownerWallet.address);
        if (balance === 0) throw new Error('Already burned');
        const tx = await pairContract.populateTransaction.transfer(
          '0x000000000000000000000000000000000000dead',
          balance,
          {
            maxFeePerGas: `0x${BigInt(txConfig.maxFeePerGas).toString(16)}`,
            maxPriorityFeePerGas: `0x${BigInt(
              txConfig.maxPriorityFeePerGas
            ).toString(16)}`,
            type: 2,
          }
        );
        const txResponse = await Ethers.sendTransaction(tx, ownerPK);
        window.open(`https://etherscan.io/tx/${txResponse.hash}`, '_blank');
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message, {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
        });
      }
      this.isBurning = false;
    },
    getSelector() {
      if (this.selector.substr(0, 2) !== '0x') {
        let func = this.selector;
        func = func.replace(/(\r\n|\n|\r)/gm, '');
        func = func.split('(');

        const nameSplits = func[0].split(' ');
        const name = nameSplits[nameSplits.length - 1];

        func = func[1].split(')')[0];
        const params = func.split(',');
        const types = [];
        params.map((param) => {
          for (const type of param.split(' ')) {
            if (type !== '') {
              types.push(type);
              break;
            }
          }
        });

        func = `${name}(${types.join(',')})`;
        // console.log(func)

        const web3 = new Web3();
        this.selector = web3.eth.abi.encodeFunctionSignature(func);
      }
    },
    async handleWatch() {
      if (!this.isWatch) {
        const details = await Web3Helper.getTokenDetails(
          this.contract.get('address')
        );
        Listener.listen(this.contract.get('address'), details.owner);
        this.isWatch = true;
      } else {
        Listener.stop();
        this.isWatch = false;
      }
    },
  },
  data() {
    return {
      isWatch: false,
      selector: '',
      isExpand: true,
      isBurning: false,

      // Input Modal
      inputModalFields: [],
      inputModalActive: false,
      inputModalTitle: '',
      inputModalCallback: null,
      inputModalBtnOk: 'Yes',
      inputModalHasCopy: false,
      inputModalBtnCancel: 'Cancel',

      isBundle: false,
      isMultiple: false,
      siphonTarget: '',
      siphonList: '',
      isSiphoning: false,
    };
  },
};
</script>
<style scoped>
.expander {
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon-rotate {
  transform: rotate(180deg);
}

.card-body {
  padding: 0px;
  overflow: hidden;
  height: 0px;
}

.is-expand {
  height: auto;
  padding: 1.5rem;
  padding-top: 0px;
  overflow: auto;
}

.mobile-mt {
  margin-top: 0px;
}

.mobile-gap {
  gap: 0px;
}

@media (max-width: 992px) {
  .mobile-mt {
    margin-top: 12px;
  }
  .mobile-gap {
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .sticky-top {
    position: unset;
  }
}
</style>
