<template>
  <div class="card">
    <div id="active-contracts-card" class="card-body p-0 py-4">
      <div class="d-flex justify-content-between align-items-center px-4 pb-3">
        <h2 class="m-0">Contract Editor</h2>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div>Solidity version:</div>
          <vs-select
            placeholder="Select solidity version"
            v-model="contractConfig.compilerVersion"
            v-if="Object.keys(contractConfig.compilerVersions).length > 0"
          >
            <vs-option
              v-for="version in Object.keys(contractConfig.compilerVersions)"
              :key="version"
              :label="version"
              :value="contractConfig.compilerVersions[version]"
            >
              {{ version }}
            </vs-option>
          </vs-select>
          <div
            class="button-text d-flex justify-content-center align-items-center"
          >
            <a style="width: 80px" @click="handleCompile()" class="btn-theme"
              >Compile</a
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <vue-monaco-editor
          class="form-control"
          style="padding:0px!important;"
          v-model="contractConfig.code"
          theme="vs-light"
          height="400px"
          language="sol"
          :options="{
            automaticLayout: true,
            minimap: { enabled: false },
          }"
        />
      </div>
      <div
        class="card-body"
        v-if="Object.keys(contractConfig.compiledContracts).length > 0"
      >
        <div class="w-100 d-flex justify-content-between">
          <vs-select
            placeholder="Select contract name"
            v-model="contractConfig.contractName"
          >
            <vs-option
              v-for="name in Object.keys(contractConfig.compiledContracts)"
              :key="name"
              :label="name"
              :value="name"
            >
              {{ name }}
            </vs-option>
          </vs-select>
          <input
            v-model="contractConfig.deployedContract"
            type="text"
            class="form-control"
            placeholder=""
            style="width: 400px; padding-left: 5px; padding-right: 5px"
          />
          <div
            class="button-text d-flex justify-content-center align-items-center"
          >
            <a
              style="width: 80px"
              @click="handleVerify()"
              class="btn-theme"
              v-if="!isVerifying"
              >Verify</a
            >
            <img v-else class="loading-icon" src="img/spinner.svg" />
            <a
              style="width: 40px"
              class="btn-theme"
              target="_blank"
              :href="'https://tokensniffer.com/token/eth/' + contractConfig.deployedContract"
              >TS</a
            >
          </div>
        </div>
        <div>{{ status }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
// import Utils from '@/helpers/Utils';
import Web3 from '@/helpers/Web3';
// import Zerion from '@/helpers/Zerion';
// import Vue from 'vue';
// import Sample from './sample.json';
import { getCompilerVersions } from '@agnostico/browser-solidity-compiler';
import axios from 'axios';
import querystring from 'querystring';
import { VueMonacoEditor } from '@guolao/vue-monaco-editor'

export default {
  name: 'ContractEditor',
  components: { VueMonacoEditor },
  computed: {
    ...mapGetters({
      liveList: 'watches/liveList',
      pastList: 'watches/pastList',
      contractConfig: 'contracts/config',
    }),
  },
  watch: {},
  async mounted() {
    this.setCompilerVersions((await getCompilerVersions()).releases);
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      fetch: 'watches/fetch',
      delete: 'watches/delete',
      setContractName: 'contracts/setContractName',
      setCompilerVersions: 'contracts/setCompilerVersions',
      compileContract: 'contracts/compileContract',
      setDeployedContract: 'contracts/setDeployedContract',
    }),
    getNetwork() {
      return Web3.getNetwork();
    },
    async handleCompile() {
      if(this.contractConfig.code.includes('unicode"NAME"') || this.contractConfig.code.includes('unicode"SYMBOL"')) {
        this.$toast.error("Contract is not updated.", {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
        });
        return;
      }
      this.compileContract();
    },
    async handleVerify() {
      this.isVerifying = true;
      const chainid = 1;
      const ETHERSCAN_API_KEY = 'JCWP6W46Y3CC7ZU8Q48PRKEF763JYZADGQ';
      const receipt = (
        await axios.post(
          `https://api.etherscan.io/v2/api?chainid=${chainid}`,
          querystring.stringify({
            optimizationUsed: '1',
            runs: '200',
            apikey: ETHERSCAN_API_KEY,
            module: 'contract',
            action: 'verifysourcecode',
            contractaddress: this.contractConfig.deployedContract,
            sourceCode: this.contractConfig.code,
            codeformat: 'solidity-single-file',
            contractname: `${this.contractConfig.contractName}`,
            compilerversion: this.contractConfig.compilerVersion.slice(8, -3),
          })
        )
      ).data;
      const guid = receipt.result;
      if (receipt.status === '0') {
        this.status = receipt.result;
        this.isVerifying = false;
        return;
      }
      if (guid === 'Contract source code already verified') {
        this.isVerifying = false;
        return;
      }
      console.log('guid :>> ', guid);
      let verifiedOrFailed = false;
      let maxTry = 30;
      do {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const result = await axios.get(
          `https://api.etherscan.io/v2/api?chainid=${chainid}&module=contract&action=checkverifystatus&guid=${guid}&apikey=${ETHERSCAN_API_KEY}`
        );
        this.status = result.data.result;
        if (this.status === 'Pass - Verified' || this.status === "Already Verified") {
          verifiedOrFailed = true;
          this.status = '';
        } else if (this.status.includes('Fail')) {
          verifiedOrFailed = true;
        }
      } while (!verifiedOrFailed && maxTry-- > 0);
      this.isVerifying = false;
    },
  },
  data() {
    return {
      isVerifying: false,
      status: '',
    };
  },
};
</script>

<style scoped></style>
